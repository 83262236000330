import React from 'react'
import clsx from 'clsx'
import { useCountdown } from '@hooks/useCountdown'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import styles from './styles/CmsCountdown.module.scss'
import { CSSModule } from '@styles/types'

type CmsCountdownProps = {
  styleOverride?: CSSModule
  teaser?: ILXTeaser
}

export const CmsCountdown: React.FC<CmsCountdownProps> = ({ styleOverride, teaser }) => {
  const { isCountDownVisible, hours, minutes, seconds } = useCountdown(teaser)

  return !isCountDownVisible ? null : (
    <div className={clsx(styleOverride && styleOverride.container, styles.container)}>
      <span className={clsx(styleOverride && styleOverride.countdown, styles.countdown)}>
        <strong>{`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
          .toString()
          .padStart(2, '0')}`}</strong>
      </span>
    </div>
  )
}
