import React from 'react'
import { IPlacement, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { ILXTeaser, ITeaserCallToAction } from '@typesApp/cmsPlacement/LXTeaser'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import { useExternalsActions } from '@hooks/useExternalsAction'
import { convertToPascalSnakeCase } from '@utils/common'
import countdownStyles from './styles/Countdown.module.scss'
import clsx from 'clsx'
import styles from './styles/index.module.scss'
import { getCtaStyle } from '@utils/cms'
import { CmsCountdown } from '@components/Cms/CmsComponents/CmsComponents-CSS/CmsCountdown'
import { LinkAsButton } from '@components/UI/Button'
import { useSite } from '@foundation/hooks/useSite'
import { Autoplay } from 'swiper/modules'

export interface IProps {
  data: IPlacement
}

const PromotionBar: React.FC<IProps> = ({ data }) => {
  const teaserItems = data?.items.filter(isLXTeaser) || []
  const promoBarName = data?.name ?? ''

  return (
    <div
      className={styles.wrapper}
      data-cm-metadata={`[{"_":"properties.placement-${promoBarName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${promoBarName}"}]}]`}
    >
      <div className={styles.content}>
        <Swiper
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          direction={'vertical'}
          loop={teaserItems?.length >= 2}
          slidesPerView={1}
          modules={[Autoplay]}
          allowTouchMove={false}
        >
          {teaserItems.map(item => (
            <SwiperSlide key={item.id}>
              <TextLinkPromoBar item={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

const CallToAction: React.FC<{
  actionSettings: ITeaserCallToAction
}> = ({ actionSettings }) => {
  const { basePath } = useStoreIdentity()
  const callToActionText = actionSettings?.callToActionText || ''
  const style = actionSettings?.style || ''
  const actions = useExternalsActions()
  const { mySite } = useSite()
  if (actionSettings.target?.type === 'Action') {
    const action = actions[actionSettings.target.idAction]
    const { color } = getCtaStyle(style)
    return (
      <button
        className={clsx(styles['button-container'], color)}
        data-element-id={'0PromotionBar_Text0_CTA'}
        data-description={convertToPascalSnakeCase(callToActionText)}
        onClick={() => action()}
      >
        {callToActionText}
      </button>
    )
  }

  const formattedUrl = actionSettings?.target?.formattedUrl || ''
  const targetUrl = actionSettings?.target?.url || ''
  const link = formattedUrl ? `${basePath}${formattedUrl ?? ''}` : targetUrl

  return (
    <LinkAsButton
      className={styles['text-link']}
      data-element-id={'0PromotionBar_Text0_LINK'}
      data-description={convertToPascalSnakeCase(callToActionText)}
      href={link}
      external={!formattedUrl}
      isLocaleDomain={true}
      locationOrigin=""
      locale={mySite.locale}
    >
      {callToActionText}
    </LinkAsButton>
  )
}

const TextLinkPromoBar: React.FC<{ item: ILXTeaser }> = ({ item }) => {
  const teaserLXCallToActionSettings: ITeaserCallToAction = item.teaserLXCallToActionSettings[0]
  let text = item.teaserText2
  if (text.includes('<a') && text.includes('data-href')) {
    text = text.replace(/<a([^>]+)data-href=['"]([^'"]+)['"]([^>]*)>/g, '<a$1data-href="$2"$3 href="$2">')
  }

  return (
    <SwiperSlide>
      <div className={clsx(styles['slider-content'])}>
        <div className={clsx(styles['text-link-container'])}>
          <CmsCountdown styleOverride={countdownStyles} teaser={item} />
          <div
            className={clsx(styles['text-content'])}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
          {teaserLXCallToActionSettings && <CallToAction actionSettings={teaserLXCallToActionSettings} />}
        </div>
      </div>
    </SwiperSlide>
  )
}

export default PromotionBar
