import { ITeaserCallToAction } from '@typesApp/cmsPlacement/LXTeaser'
import { areEquals } from './common'

export const getVariant = (variantName: string, colorName: string, themeName: string) => {
  if (colorName === 'primary') {
    if (variantName === 'outline') {
      return themeName === 'light' ? 'outlined-light' : 'outlined-dark'
    } else {
      return themeName === 'light' ? 'contained-light' : 'contained-dark'
    }
  }
  return variantName === 'outline' ? 'outlined' : 'contained'
}

export const getHref = (currentPath: string | undefined, url: string, callToActionHash: string | undefined): string => {
  const hasActionHash = Boolean(callToActionHash)

  if (url && hasActionHash && !areEquals(currentPath || '', url)) {
    return `${url}#${callToActionHash}`
  }

  if (areEquals(currentPath || '', url) && hasActionHash) {
    return `#${callToActionHash}`
  }

  return url ?? '#NOT_FOUND_FORMATTED_URL'
}

export const getCtaStyle = (style: ITeaserCallToAction['style']) => {
  const [, variantName, colorName, themeName] = style.split('-')

  const variant = getVariant(variantName, colorName, themeName)

  const color = colorName ?? 'primary'

  return {
    variant,
    color,
  }
}
